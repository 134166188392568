<template>
  <div
    class="page-tree"
    :class="(moving ? ' moving' : '') + (cloning ? ' cloning' : '')"
  >
    <div class="row top-row">
      <div v-if="tree_type !== 'company'" class="mb-3 col-lg-auto col-12">
        <search @search="handleSearch" size="lg"></search>
      </div>
      <div class="mb-3 col">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button
                v-if="user.manageteams || user.managecompany"
                @click="openManagerImportModal"
                class="btn-sm btn btn-secondary"
            >
              <img
                  class="hide-dark"
                  src="../assets/icons/icon-navigation-add.svg"
                  alt=""
              />
              <img
                  class="hide-light"
                  src="../assets/icons/icon-navigation-add-light.svg"
                  alt=""
              />
              <span>{{ $t("message.import_managers") }}</span>
            </button>
          </div>
          <div class="col-auto">
            <button
              v-if="user.manageteams || user.managecompany"
              @click="openTeamImportModal"
              class="btn-sm btn btn-secondary"
            >
              <!-- <button
              v-if="user.manageteams || user.managecompany"
              @click="initTree"
              class="btn-sm btn btn-secondary"
            > -->
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.import") }}</span>
            </button>
          </div>
          <div class="col-auto">
            <button
              v-if="user.manageteams || user.managecompany"
              @click="addTeam"
              class="btn-sm btn btn-secondary"
            >
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.team") }}</span>
            </button>
          </div>
          <div class="col-auto">
            <button
              v-if="user.manageusers || user.managecompany"
              @click.prevent="handleAddUser"
              class="btn-sm btn btn-primary"
            >
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.user") }}</span>
            </button>
          </div>
        </div>
      </div>

      <!--      <div class="col-lg-6 tree-filters row align-items-center">-->
      <!--        <div v-if="tree_type !== 'company'" class="col-auto d-flex">-->
      <!--          <search @search="handleSearch" size="lg"></search>-->
      <!--        </div>-->
      <!--        <div class="d-none col-auto">-->
      <!--          <input type="date" class="input-lg">-->
      <!--        </div>-->
      <!--        <div class="col-auto">-->
      <!--          <button class="btn btn-primary btn-sm" @click.prevent.stop="openFilterModal">-->
      <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
      <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt="">-->
      <!--            {{ $t("message.filter") }}-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="ms-auto col-auto justify-content-end row tree-actions">-->
      <!--        <div class="col-auto">-->
      <!--          <button v-if="user.manageteams || user.managecompany" @click="openTeamImportModal" class="btn-sm btn btn-secondary">-->
      <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
      <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{-->
      <!--              $t("message.import")-->
      <!--            }}-->
      <!--          </button>-->
      <!--        </div>-->
      <!--        <div class="col-auto">-->
      <!--          <button v-if="user.manageteams || user.managecompany" @click="addTeam" class="btn-sm btn btn-secondary">-->
      <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
      <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{ $t("message.team") }}-->
      <!--          </button>-->
      <!--        </div>-->
      <!--        <div v-if="user.manageusers ||user.managecompany" class="col-auto">-->
      <!--          <button @click.prevent="handleAddUser" class="btn-sm btn btn-primary">-->
      <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
      <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{ $t("message.user") }}-->
      <!--          </button>-->
      <!--        </div>-->

      <!--      </div>-->
    </div>
    <div class="row m-0 content-row d-flex boxshadow">
      <div
        class="
          p-0
          col-xl-6 col-lg-12
          card
          flex-grow-1
          rounded-end-0
          boxshadow-0
        "
        :class="activeTeam ? 'd-none d-xl-block' : ''"
      >
        <div
          :class="tree_type === 'company' ? 'bgc-okergeel' : 'bgc-magenta'"
          class="card-header rounded-end-0 c-200"
        >
          <span class="overline text-sm text-white">{{
            $t("message." + tree_type + "_tree")
          }}</span>
        </div>
        <div class="card-body company-tree border-end">
          <div class="d-flex justify-content-between">
            <div class="company-tree-buttons actions mb-3">
              <div class="btn-group">
                <button
                  v-if="
                    user.ismanager || user.manageteams || user.managecompany
                  "
                  :class="tree_type === 'company' ? 'active' : ''"
                  @click.prevent="changeTree('company')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.company") }}</span>
                </button>
                <button
                  v-if="
                    user.issegmentmanager ||
                    user.managesegment ||
                    user.managecompany
                  "
                  :class="tree_type === 'segment' ? 'active' : ''"
                  @click.prevent="changeTree('segment')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.segment") }}</span>
                </button>
                <button
                  :class="tree_type === 'teams' ? 'active' : ''"
                  @click.prevent="changeTree('teams')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.teams") }}</span>
                </button>
              </div>
            </div>
            <div class="company-tree-buttons actions mb-3" style="z-index: 0">
              <div v-if="tree_type === 'teams'" class="btn-group">
                <button
                  @click.prevent="teamListType = 'all'"
                  :class="teamListType === 'all' && 'active'"
                  class="btn btn-secondary btn-sm"
                >
                  {{ $t("message.all_teams") }}
                </button>
                <button
                  @click.prevent="teamListType = 'orphaned'"
                  :class="teamListType === 'orphaned' && 'active'"
                  class="btn btn-secondary btn-sm"
                >
                  {{ $t("message.orphaned") }}
                </button>
              </div>
            </div>
          </div>

          <div v-if="treeLoaded" class="trees" :style="'max-height: ' + maxHeight + 'px'">
            <c-tree
              v-if="tree_type === 'company'"
              id="main-tree"
              :activeTeamId="activeTeam ? activeTeam.id : null"
              @setActiveTeam="setActiveTeam"
              @setTree="handleReload"
              @setActiveTeamManagers="setActiveTeamManagers"
              @reloadTree="initTree"
              :tree="trees"
            ></c-tree>
            <ul class="tree-ul no-tree" v-else-if="tree_type === 'segment'">
              <paginated
                style="min-height: 150px"
                @change="handleTeamPageChange"
                :total-rows="totalTeamRows"
                :per-page="10"
                :request="teamRequest"
              >
                <tree-item
                  :class="
                    activeTeam
                      ? activeTeam.id === tree.id
                        ? 'active'
                        : ''
                      : ''
                  "
                  :type="'segment'"
                  :selected="selectedTeams.includes(tree.id)"
                  @reloadAll="reloadAll"
                  @reloadTree="initTree"
                  @setLoaderFalse="treeLoaded = false"
                  @setActiveTeam="setActiveTeam"
                  @setTree="handleReload"
                  @setActiveTeamManagers="setActiveTeamManagers"
                  @selectTeam="selectTeam"
                  v-bind:key="tree"
                  v-for="tree in teams"
                  :content="tree"
                ></tree-item>
                <template #paginationFooterLeft>
                  <div class="d-flex align-items-center">
                    <checkbox
                      @change="selectAllTeams"
                      :model-value="selectedTeams.length === teams.length"
                    />
                    <span class="mb-0 ms-2 text-sentence">{{
                      $t("message.select_all")
                    }}</span>
                  </div>
                </template>
              </paginated>
            </ul>
            <ul class="tree-ul no-tree" v-else>
              <!--tr:              {{totalTeamRows}}-->
              <paginated
                style="min-height: 150px"
                @change="handleTeamPageChange"
                :total-rows="totalTeamRows"
                :per-page="10"
                :request="teamRequest"
              >
                <tree-item
                  :class="
                    activeTeam
                      ? activeTeam.id === tree.id
                        ? 'active'
                        : ''
                      : false
                  "
                  :type="'teams'"
                  :selected="selectedTeams.includes(tree.id)"
                  @reloadAll="reloadAll"
                  @reloadTree="initTree"
                  @setLoaderFalse="treeLoaded = false"
                  @setActiveTeam="setActiveTeam"
                  @setTree="handleReload"
                  @setActiveTeamManagers="setActiveTeamManagers"
                  @selectTeam="selectTeam"
                  v-bind:key="tree"
                  v-for="tree in teams"
                  :content="tree"
                ></tree-item>
                <template #paginationFooterLeft>
                  <div class="d-flex align-items-center">
                    <checkbox
                      @change="selectAllTeams"
                      :model-value="selectedTeams.length === teams.length"
                    />
                    <span class="mb-0 ms-2 text-sentence">{{
                      $t("message.select_all")
                    }}</span>
                  </div>
                </template>
              </paginated>
            </ul>
            <div
              v-if="treeLoaded"
              class="d-flex align-items-center justify-content-between"
            >
              <div v-if="selectedTeams.length > 0">
                <button
                  class="btn btn-sm btn-outline-danger border-danger"
                  @click="confirmDeleteTeams"
                >
                  {{
                    $t("message.delete_teams", { amt: selectedTeams.length })
                  }}
                </button>
              </div>
            </div>
          </div>
          <div v-else class="position-relative" style="min-height: 150px">
            <div
              class="
                position-absolute
                top-50
                start-50
                translate-middle
                container-spinner
              "
            >
              <div class="spinner-border" role="status">
                <span class="visually-hidden"
                  >{{ $t("message.loading") }}...</span
                >
              </div>
            </div>
          </div>
          <p
            class="text-capitalize text-sentence"
            v-if="treeLoaded && trees.length === 0"
          >
            {{ $t("message.no_data_available") }}
          </p>
        </div>
      </div>
      <div
        v-if="activeTeam"
        class="
          p-0
          col-xl-6 col-lg-12
          card
          flex-grow-1
          rounded-start-0
          boxshadow-0
        "
      >
        <!--        <pre>{{ treeChildren }}</pre>-->
        <div
          :class="[
            tree_type === 'company' ? 'bgc-okergeel' : 'bgc-magenta',
            activeTeam ? '' : 'd-none d-xl-block',
          ]"
          class="card-header rounded-start-0 c-50"
        >
          <span class="overline">
            <!--             <span class="overline text-sm text-white">-->
            <span :class="activeTeam ? 'd-xl-none' : 'd-none'">
              <button
                class="btn btn-link p-0"
                @click.prevent="activeTeam = null"
              >
                &lt;
                <span class="text-sentence">{{ $t("message.goBack") }}</span>
              </button>
            </span>
            <!--          </span>-->
          </span>
        </div>
        <div class="card-body team-tree">
          <div class="d-flex d-xl-none actions justify-content-between">
            <div
              v-if="
                activeTeam.issegment !== true &&
                (user.manageteams || user.managecompany)
              "
              class="
                position-relative
                add-user-wrapper
                d-flex
                align-items-center
                me-3
              "
            >
              <input
                :placeholder="
                  $t('message.searchToAdd', {
                    userListType: $t('message.' + userListType),
                  })
                "
                v-model="userNameSearch"
                @blur="handleBlur"
                @keyup="handleKeyupUsers"
                type="text"
                class=""
              />
              <ul
                class="user-suggestion-list position-absolute"
                v-show="userNameSuggestions.length"
              >
                <li
                  @mousedown="handleClickUser(user)"
                  v-bind:key="user"
                  v-for="user in userNameSuggestions"
                >
                  {{ user.firstname }}&nbsp;{{ user.lastname }}
                </li>
              </ul>
            </div>
            <div
              v-if="userListType === 'users'"
              class="text-nowrap tree-action graph border-start px-2"
            >
              <img src="../assets/icons/Icon-tree-user-profile.svg" alt="" />{{
                activeTeam.zerglingcount
              }}
            </div>
            <div v-else class="text-nowrap tree-action graph border-start px-2">
              <img
                src="../assets/icons/Icon-tree-manager-profile.svg"
                alt=""
              />{{ activeTeam.overlordcount }}
            </div>
          </div>
          <ul class="team">
            <li
              v-if="usersLoaded"
              class="team-detail team-row branch-list active-branch"
            >
              <div class="team-content d-flex align-items-center">
                <div class="team-name flex-grow-1">
                  <h5 class="mb-0">{{ activeTeam.title }}</h5>
                </div>
                <div class="d-none d-lg-flex actions">
                  <div
                    v-if="
                      activeTeam.issegment !== true &&
                      (user.manageteams || user.managecompany)
                    "
                    class="
                      position-relative
                      add-user-wrapper
                      d-flex
                      align-items-center
                      me-3
                    "
                  >
                    <input
                      :placeholder="
                        $t('message.searchToAdd', {
                          userListType: $t('message.' + userListType),
                        })
                      "
                      v-model="userNameSearch"
                      @blur="handleBlur"
                      @keyup="handleKeyupUsers"
                      type="text"
                      class=""
                    />
                    <ul
                      class="user-suggestion-list position-absolute"
                      v-show="userNameSuggestions.length"
                    >
                      <li
                        @mousedown="handleClickUser(user)"
                        v-bind:key="user"
                        v-for="user in userNameSuggestions"
                      >
                        {{ user.firstname }}&nbsp;{{ user.lastname }}
                      </li>
                    </ul>
                  </div>
                  <div
                    v-if="userListType === 'users'"
                    class="text-nowrap tree-action graph border-start px-2"
                  >
                    <img
                      src="../assets/icons/Icon-tree-user-profile.svg"
                      alt=""
                    />{{ activeTeam.zerglingcount }}
                  </div>
                  <div
                    v-else
                    class="text-nowrap tree-action graph border-start px-2"
                  >
                    <img
                      src="../assets/icons/Icon-tree-manager-profile.svg"
                      alt=""
                    />{{ activeTeam.overlordcount }}
                  </div>
                </div>
              </div>
              <paginated
                v-if="showList"
                @change="handlePageChange"
                :total-rows="totalRows"
                :per-page="10"
                :request="request"
              >
                <userlist
                  :team="activeTeam.id"
                  @saveUser="handleSaved"
                  @reloadList="handleReload"
                  :type-list="userListType"
                  :issegment="tree_type === 'segment'"
                  :show-vitamins="
                    this.tree_type === 'company' && userListType === 'users'
                  "
                  :users="loadedUsers"
                ></userlist>
              </paginated>
              <div class="mt-3 border-top pt-3" v-if="addingMembers.length">
                <h5 class="text-sentence">
                  {{ $t("message.addingMembers") }}:
                </h5>
                <ul class="no-style">
                  <li
                    class="add-list"
                    v-bind:key="member.id"
                    v-for="(member, ind) in addingMembers"
                  >
                    <span class="name">
                      {{ member.firstname }}&nbsp;{{ member.lastname }}
                    </span>
                    <span class="remove cursor-pointer">
                      <img
                        @click="cancelAdd(ind)"
                        src="../assets/icons/icon-navigation-close.svg"
                        alt=""
                      />
                    </span>
                  </li>
                </ul>
                <div class="text-right actions d-flex justify-content-end">
                  <button
                    @click.stop="addingMembers = []"
                    class="me-2 btn btn-secondary btn-sm"
                  >
                    <span>{{ $t("message.cancel") }}</span>
                  </button>
                  <button
                    @click.stop="confirmAddMembers"
                    class="btn btn-primary btn-sm"
                  >
                    <span>{{ $t("message.confirm") }}</span>
                  </button>
                </div>
              </div>
              <!--              <pre>{{test}}</pre>-->
            </li>
            <div v-else class="position-relative" style="min-height: 150px">
              <div
                class="
                  position-absolute
                  top-50
                  start-50
                  translate-middle
                  container-spinner
                "
              >
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"
                    >{{ $t("message.loading") }}...</span
                  >
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TreeItem from "@/views/components/TreeItem";
import Userlist from "@/views/components/Userlist";
import FilterModal from "@/views/components/modals/FilterModal";
import Search from "@/views/components/simple/Search";
import Paginated from "@/views/components/simple/Paginated";
import qs from "qs";
import Tree from "@/views/components/Tree";
import TeamModal from "@/views/components/modals/TeamModal";
import TreeItem from "@/views/components/TreeItem";
import TeamImportModal from "@/views/components/modals/TeamImportModal";
import UserModal from "@/views/components/modals/UserModal";
import SubmissionModal from "@/views/components/modals/SubmissionModal";
import Checkbox from "@/views/components/simple/Checkbox";
import _ from "lodash";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import ManagerImport from "@/views/components/modals/ManagerImport";

export default {
  components: {
    TreeItem,
    Paginated,
    Search,
    Userlist,
    Checkbox,
    // TreeItem,
    cTree: Tree,
  },
  methods: {
    reloadAll() {
      this.getTeams();
      this.getTeamManagers();
      this.getTeamMembers();
    },
    handleBlur() {
      this.userNameSearch = "";
      this.userNameSuggestions = [];
    },
    handleSearch(val) {
      if (this.tree_type !== "company") {
        this.getTeams("&title_contains=" + val);
      }
    },
    handleAddUser() {
      this.showUserPopup({});
    },
    showUserPopup(user) {
      this.$store.dispatch("openModal", {
        component: UserModal,
        properties: {
          userNode: user,
          type: "settings",
        },

        options: {
          size: "xl",
          centered: false,
        },
        events: {
          userSaved: this.handleSaveUser,
        },
      });
    },

    async handleSaveUser(user) {
      this.usersLoaded = false;
      if (this.userListType === "managers") {
        await this.axios.put("/users/" + user.id, {
          swarms: this.activeTeam,
        });
        this.activeTeam.overlordcount += 1;
      } else {
        await this.axios.put("/users/" + user.id, {
          member: this.activeTeam,
        });
        this.activeTeam.zerglingcount += 1;
      }
      // await this.changeTree(this.tree_type)
      this.totalRows++;
      if (this.userListType === "managers") {
        this.getTeamManagers();
      } else {
        this.getTeamMembers();
      }
      // this.setActiveTeam(act)
      this.usersLoaded = true;
      //reload userlist
    },

    async reloadUsers() {
      this.totalRows = 0;
      this.loadedUsers = [];
      //console.log('reloading users')
      this.usersLoaded = false;
      if (this.userListType === "managers") {
        await this.getTeamManagers();
      } else {
        await this.getTeamMembers();
      }
      this.usersLoaded = true;
    },

    async handleReload(swarm) {
      let index = this.findTeamIndex(this.trees, swarm.id);
      if (swarm.parents && swarm.parents.length) {
        for (const team of swarm.parents) {
          const parentIndex = this.findTeamIndex(this.trees, team.id);
          if (parentIndex !== "none" && parentIndex !== index) {
            await this.getTree(this.tree_type)
            return;
          }
        }
      }

      if (isNaN(index)) {
        index = index.split('#')
      } else {
        index = [index];
      }
      _.set(this.trees, index, swarm);

      this.mitt.emit(`updatedSwarm-${swarm.id}`, swarm);
      this.setActiveTeam(swarm);
    },
    handleSaved(user) {
      // console.log('s',user)
      this.loadedUsers = this.loadedUsers.map((u) => {
        if (u.id === user.id) {
          u = user;
        }
        return u;
      });
      // this.totalRows = 0
      // console.log('user was saved')
      // if (this.userListType === 'managers') {
      //   this.setActiveTeamManagers(this.activeTeam)
      // } else {
      //   this.setActiveTeam(this.activeTeam)
      // }
    },
    searchTeam() {
      let id = this.findTeamId(this.trees, this.searchFor);
      let found = id.split("#");
      found.forEach((elId) => {
        const teamElements = document.querySelectorAll(".t-" + elId);
        teamElements.forEach((teamElement) => {
          // console.log('el', teamElement)
          teamElement.classList.add("show-children");
          teamElement.classList.remove("hide-children");
          teamElement.parentNode.classList.add("parent-show-children");
          teamElement.parentNode.classList.remove("parent-hide-children");
        });
      });
    },
    findTeamId(tree, needle) {
      // let found = false;
      let i;
      let temp = "none";
      let id = 0;
      let parentid = 0;
      for (i = 0; i < tree.length; i++) {
        if (tree[i].id == needle) {
          temp = "found";
          id = tree[i].id;
          break;
        } else {
          id = this.findTeamId(tree[i].children, needle);
          if (id != "none") {
            temp = "foundrec";
            parentid = tree[i].id;
            break;
          }
        }
      }
      if (temp === "found") {
        return id;
      } else if (temp === "foundrec") {
        return parentid + "#" + id;
      } else {
        return "none";
      }
    },
    findTeamIndex(tree, needle) {
      let found = "none";
      let index = [];

      for (const [i, item] of tree.entries()) {
        if (item.id === needle) {
          found = "found";
          index.push(i);
          break;
        } else {
          let childindex = this.findTeamIndex(item.children, needle);
          if (childindex !== 'none') {
            found = "found"
            index.push(i, childindex)
            break;
          }
        }
      }

      if (found === "found") {
        return index.join('#children#');
      }

      return "none";
    },
    onlyChildren(team) {
      return {
        title: team.title,
        children: team.children.map((team) => {
          return this.onlyChildren(team);
        }),
      };
    },
    addTeam() {
      this.showTeamPopup();
    },
    showTeamPopup() {
      this.$store.dispatch("openModal", {
        component: TeamModal,
        events: {
          submitTeam: (t) => this.handleSubmitModal(t),
        },
        properties: { team: {} },
      });
    },
    handleSubmitModal() {
      // console.log('submit team', e)
      this.getTree(this.tree_type);
      // console.log('tree item submit team handler')
    },
    async handleKeyupUsers() {
      if (this.userNameSearch.length > 2) {
        const query = qs.stringify({
          _where: {
            _or: [
              { firstname_contains: this.userNameSearch },
              { lastname_contains: this.userNameSearch },
              { email_contains: this.userNameSearch },
            ],
          },
        });
        const get = await this.axios.get("/users?" + query + "&isdeleted_null=true&_limit=10");
        this.userNameSuggestions = get.data;
      } else if (this.userNameSearch.length === 0) {
        this.userNameSuggestions = [];
      }
    },
    handleClickUser(user) {
      // console.log('clickuser',user)
      const loadedIds = this.loadedUsers.map((user) => {
        return user.id;
      });
      // console.log('loadedids',loadedIds,user)
      if (!this.addingMembers.includes(user) && !loadedIds.includes(user.id)) {
        this.addingMembers.push(user);
      }
      this.userNameSuggestions = [];
      this.userNameSearch = "";
    },
    cancelAdd(ind) {
      this.addingMembers.splice(ind, 1);
    },
    async confirmAddMembers() {
      this.usersLoaded = false;
      let q =
        "/swarms/lingget/" +
        this.activeTeam.id +
        "?_member=" +
        this.activeTeam.id;
      if (this.userListType === "managers") {
        q =
          "/swarms/lingget/" +
          this.activeTeam.id +
          "?_swarms=" +
          this.activeTeam.id;
      }
      const get = await this.axios.get(q);
      const current_user_ids = get.data.map((user) => {
        return user.id;
      });
      const adding_user_ids = this.addingMembers.map((user) => {
        return user.id;
      });
      let updated;
      if (this.userListType === "managers") {
        updated = await this.axios.put("/swarms/" + this.activeTeam.id, {
          overlords: current_user_ids.concat(adding_user_ids),
        });
        // console.log('updated managers')
        this.getTeamManagers();
      } else {
        updated = await this.axios.put("/swarms/" + this.activeTeam.id, {
          zerglings: current_user_ids.concat(adding_user_ids),
        });
        // console.log('updated members')
        this.getTeamMembers();
      }
      // Update count
      // console.log('updated');
      this.activeTeam.zerglingcount = updated.data.zerglingcount;
      this.activeTeam.overlordcount = updated.data.overlordcount;
      const act = this.activeTeam;
      // await this.changeTree(this.tree_type);
      await this.handleReload(act);
      this.addingMembers = [];
      this.setActiveTeam(act);
      // console.log(this.activeTeam);
      this.usersLoaded = true;
    },
    async changeTree(type) {
      // console.log('changetree',type)
      this.tree_type = type;
      if (type === "teams" || type === "segment") {
        await this.getTeams();
      } else {
        await this.getTree(type);
      }
    },
    openFilterModal() {
      this.$store.dispatch("openModal", {
        component: FilterModal,
        properties: {},
        options: {
          centered: true,
        },
      });
    },
    openManagerImportModal() {
      this.$store.dispatch("openModal", {
        component: ManagerImport,
        properties: {},
        options: {
          size: "xl",
        },
        events: {
          importedManagers: this.getTree,
        },
      });
    },
    openTeamImportModal() {
      this.$store.dispatch("openModal", {
        component: TeamImportModal,
        properties: {},
        options: {
          size: "xl",
        },
        events: {
          importedTeams: this.getTree,
        },
      });
    },
    handlePageChange(users) {
      this.loadedUsers = users;
      // Resize tree with size of user list
      this.$nextTick(() => {
        if (users.length > 10) {
          // console.log(document.querySelector('.team-detail').clientHeight);
          this.maxHeight = document.querySelector('.team-detail').clientHeight;
        } else {
          this.maxHeight = 593;
        }
      })
    },
    handleTeamPageChange(teams) {
      this.teams = teams;
      this.selectedTeams = [];
      if (window.outerWidth > 1200) {
        this.activeTeam = this.teams[0];
        if (this.userListType === "users") {
          this.setActiveTeam(this.teams[0]);
        } else {
          this.setActiveTeamManagers(this.teams[0]);
        }
      }
    },
    async getTree(type = "company") {
      // console.log('getting tree',type)
      this.trees = [];
      let filter = "?showStats=true";
      let r = "/swarms/tree";
      if (type === "segment") {
        r = "/swarms/segments";
      }
      this.treeLoaded = false;
      const get = await this.axios.get(r + filter);
      // console.log(get);
      // console.log('g',get)
      const tree = get.data;
      // const tree = [];
      if (tree.length > 0) {
        tree[0].isOpen = true;
        this.trees = tree;
        if (window.outerWidth > 1200) {
          this.setActiveTeam(tree[0]);
        }
      }
      this.treeLoaded = true;
      // console.log('tree fetch', tree)
    },
    selectTeam(object) {
      const index = this.selectedTeams.indexOf(object.id);
      if (object.checked) {
        if (index == -1) {
          this.selectedTeams.push(object.id);
        }
      } else {
        this.selectedTeams.splice(index, 1);
      }
    },
    selectAllTeams(event) {
      const checked = event.currentTarget.checked;
      if (checked) {
        this.selectedTeams = _.uniq(this.teams.map((team) => team.id));
      } else {
        this.selectedTeams = [];
      }
    },
    async confirmDeleteTeams() {
      this.$store.dispatch("openModal", {
        component: ConfirmDelete,
        properties: {
          id: this.selectedTeams,
          title: this.selectedTeams.length + " " + this.$t("message.teams"),
        },
        options: {
          centered: true,
        },
        events: {
          submitModal: this.deleteSwarms,
        },
      });
    },
    async deleteSwarms(ids) {
      this.treeLoaded = false;
      this.selectedTeams = [];
      try {
        for (const id of ids) {
          const team = this.teams.find((team) => team.id === id);
          await this.axios.put("/swarms/" + id, {
            visibility: false,
            title: team.title + " [Deleted]",
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.reloadAll();
      }
    },
    async getTeamChildren(team) {
      // console.log("testing");
      if (team.children.length > 0 || team.childCount < 1) {
        return;
      }
      if (this.tree_type === "company") {
        if (team.childCount > 0 && !team.children.length) {
          const {data: teamTree} = await this.axios.get('/swarms/tree?showStats=true&base=' + team.id);
          let index = this.findTeamIndex(this.trees, team.id);
          if (isNaN(index)) {
            index = index.split('#')
          } else {
            index = [index];
          }
          _.set(this.trees, index, teamTree[0]);
          this.mitt.emit('loadedChildren', team.id)
        }
      }
    },
    setActiveTeam(team) {
      if (team) {
        this.activeTeam = team;
        this.totalRows = team ? team.zerglingcount : 0;
        // this.getTeamChildren(team);
        this.getTeamMembers();
      }
    },
    setActiveTeamManagers(team) {
      this.activeTeam = team;
      this.totalRows = team ? team.overlordcount : 0;
      this.getTeamManagers();
    },
    getTeamManagers() {
      this.showList = false;
      this.request = "";
      this.userListType = "managers";
      this.totalRows = 0;
      this.request =
        "/swarms/lingget/" +
        this.activeTeam.id +
        "?_swarms=" +
        this.activeTeam.id;
      this.totalRows = this.activeTeam.overlordcount;
      this.showList = true;
    },
    async getTeams(filter = "") {
      this.totalTeamRows = 0;
      // console.log('getteams',this.tree_type)
      // this.teamRequest = '/swarms?issegment_ne=true'
      // let q = '/swarms/count?issegment_ne=true' //todo request /count on /swarms/overlords
      let q =
        "/swarms/overlords/count?issegment_ne=true&visibility=true" + filter; //todo request /count on /swarms/overlords
      this.teamRequest =
        "/swarms/overlords?issegment_ne=true&visibility=true" + filter;
      if (this.user.managecompany) {
        this.teamRequest = "/swarms?issegment_ne=true&visibility=true" + filter;
        q = "/swarms/count?issegment_ne=true&visibility=true" + filter;
      }
      if (this.tree_type === "segment") {
        if (this.user.managecompany) {
          this.teamRequest = "/swarms?issegment=true&visibility=true" + filter;
          q = "/swarms/count?issegment=true&visibility=true";
        } else {
          this.teamRequest =
            "/swarms/overlords?issegment=true&visibility=true" + filter;
          q = "/swarms/overlords/count?issegment=true&visibility=true";
        }
      }

      this.treeLoaded = false;
      const get = await this.axios.get(q + filter);
      // this.teams = get.data
      // console.log('getteams', get.data)
      this.totalTeamRows = get.data;
      this.treeLoaded = true;
    },
    getTeamMembers() {
      this.showList = false;
      //console.log('getting members')
      this.request = "";
      this.userListType = "users";
      this.totalRows = 0;
      this.request =
        "/swarms/lingget/" +
        this.activeTeam.id +
        "?_member=" +
        this.activeTeam.id;
      this.totalRows = this.activeTeam.zerglingcount;
      this.showList = true;
    },
    async initTree() {
      // console.log("initTree");
      await this.getTree();
      this.mitt.emit("loadcomplete", { complete: true });
      // console.log("initTree completed");
    },
    async openSubmissionModal(userid, activeId) {
      this.$store.dispatch("openModal", {
        component: SubmissionModal,
        properties: {
          userid,
          activeId,
        },
        options: {
          size: "lg",
        },
        events: {
          importedTeams: this.getTree,
        },
      });
    },
  },
  watch: {
    activeTeam() {
      this.addingMembers = [];
    },
    teamListType(val, oldval) {
      if (oldval !== val && val === "orphaned") {
        this.getTeams(
          "&" +
            qs.stringify({
              parents_null: true,
              _where: {
                _or: [{ isbase_null: true }, { isbase_ne: true }],
              },
            })
        );
      } else {
        this.getTeams();
      }
    },
    tree_type() {
      this.selectedTeams = [];
    },
  },
  async created() {
    await this.initTree();
    if (this.$route.query.submission && this.$route.query.user) {
      this.openSubmissionModal(
        this.$route.query.user,
        this.$route.query.submission
      );
    }
    this.mitt.on('loadChildren', this.getTeamChildren);
  },
  beforeUnmount() {
    this.mitt.off('loadChildren');
  },
  data() {
    return {
      treeLoaded: false,
      teams: [],
      showList: true,
      searchFor: null,
      openItem: null,
      cloning: false,
      moving: false,
      addingMembers: [],
      userListType: "users",
      tree_type: "company",
      selected: [],
      totalRows: 0,
      totalTeamRows: 0,
      request: "",
      teamRequest: "/swarms?issegment_ne=true",
      activeTeam: null,
      loadedUsers: [],
      searchVal: "",
      isSearchOpen: false,
      trees: [],
      userNameSearch: "",
      userNameSuggestions: [],
      test: null,
      usersLoaded: true,
      selectedTeams: [],
      teamListType: "all",
      maxHeight: 593,
    };
  },
};
</script>

<style lang="scss">
.trees {
  //max-height: 593px;
  overflow: auto;
}
.tree-type-segment {
  ul {
    padding-left: 0;
  }

  .branch-list.active-branch li:after {
    display: none;
  }
}

.company,
.team-detail {
  .tree-action {
    &:first-child,
    &:last-child {
      width: 60px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.user {
  .tree-action {
    &:first-child,
    &:last-child {
      width: 40px;
    }
  }
}

.collapse-team {
  display: block;
}

.expand-team {
  display: none;
}

.collapsed {
  .collapse-team {
    display: none;
  }

  .expand-team {
    display: block;
  }
}

.user-suggestion-list {
  top: 100%;
  left: 8px;
  width: calc(100% - 16px);
  z-index: 1;
  background-color: #fff;
  padding: 0px;
  margin: 0;
  list-style: none;
  box-shadow: 0px 3px 6px #29282c40 !important;

  li {
    padding: 5px 15px;
    cursor: pointer;

    &:after {
      display: none;
    }

    &:hover {
      background-color: #f2f2f5;
    }
  }
}

ul.no-style {
  list-style-type: none;
}

.add-list {
  display: flex;
  height: 48px;
  align-items: center;
}

.moving {
  //background-color: red;
}

.swarm-container .team-content {
  @media all and (max-width: 991px) {
    flex-wrap: wrap;
  }
}

//.moving{
//  .no-children .dragArea, .hidechildren  + .dragArea {
//    min-height: 24px;
//    padding-bottom: 24px;
//    background-image: url(../assets/icons/icon-navigation-add.svg);
//  }
//}
</style>