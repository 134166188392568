<template>
  <draggable
    class="dragArea swarm-dragarea tree-ul"
    tag="ul"
    :data-id="tree.id"
    handle=".handle"
    :list="tree"
    :group="{
      name: 'g1',
      pull: () => {
        return this.cloning ? 'clone' : true;
      },
    }"
    item-key="id"
    filter=".base"
    @setTree="setTree"
    @setActiveTeam="setActiveTeam"
    @setActiveTeamManagers="setActiveTeamManagers"
    @start="handlestart"
    @end="handleend"
    @choose="handleChoose"
    :move="handleMove"
  >
    <template #item="{ element }">
      <li
        class="swarm-container"
        :data-id="element.id"
        :class="
          element.isOpen ? ' parent-show-children' : ' parent-hide-children'
        "
        @dragstart="startClone"
      >
        <tree-item
          :class="activeTeamId === element.id ? 'active' : ''"
          @setActiveTeam="setActiveTeam"
          @setTree="setTree"
          @setActiveTeamManagers="setActiveTeamManagers"
          @toggleChildren="handleToggleChildren"
          @openChildren="handleOpenChildren"
          :content="element"
        ></tree-item>
        <nested-draggable
          :active-team-id="activeTeamId"
          @setTree="setTree"
          @setActiveTeam="setActiveTeam"
          @setActiveTeamManagers="setActiveTeamManagers"
          tag="ul"
          :class="
            (element.isbase ? ' isbase-true' : ' isbase-false') +
            (element.childCount > 0 ? ' has-children' : ' no-children') +
            (element.isOpen ? ' show-children' : ' hide-children') +
            ' t-' +
            element.id
          "
          :data-id="element.id"
          :tree="element.children"
        />
        <!--        [+]-->
      </li>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import TreeItem from "@/views/components/TreeItem";

export default {
  components: {
    TreeItem,
    draggable,
  },
  props: {
    activeTeamId: null,
    tree: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      cloning: false,
      saving: true, //set to false to disable api save requests
      dragging: false,
      // showChildren: false
    };
  },

  emits: ["endmove", "setActiveTeam", "setActiveTeamManagers", "setTree"],
  methods: {
    handleToggleChildren(el) {
      const dragArea = el.parentNode.querySelector(".dragArea");
      const draggedOnParent = dragArea.parentNode;

      if (dragArea.classList.contains("hide-children")) {
        dragArea.classList.add("show-children");
        dragArea.classList.remove("hide-children");
        draggedOnParent.classList.remove("parent-hide-children");
        draggedOnParent.classList.add("parent-show-children");
      } else {
        dragArea.classList.remove("show-children");
        dragArea.classList.add("hide-children");
        draggedOnParent.classList.remove("parent-show-children");
        draggedOnParent.classList.add("parent-hide-children");
      }

      // this.showChildren = !this.showChildren
    },
    handleOpenChildren(el) {
      const dragArea = el.parentNode.querySelector(".dragArea");
      const draggedOnParent = dragArea.parentNode;

      dragArea.classList.add("show-children");
      dragArea.classList.remove("hide-children");
      draggedOnParent.classList.remove("parent-hide-children");
      draggedOnParent.classList.add("parent-show-children");

      // this.showChildren = !this.showChildren
    },
    startClone(e) {
      if (e.ctrlKey === true) {
        document.querySelector("body").classList.add("cloning");
        this.cloning = true;
      }
    },
    isMoveInvalid(e) {
      let invalid = false;

      const to_children = Array.from(e.to.children);
      const to_children_ids = to_children.map((child_el) => {
        return child_el.dataset.id;
      });

      const dragged_id = e.dragged.dataset.id;
      const to_id = e.to.dataset.id;
      const dragged_children_ids = this.getChildIds(e.dragged);
      const to_parent_ids = this.getParentIds(e.to);

      if (
        to_children_ids.includes(dragged_id) || // dragged element can't already be immediate child of new parent
        dragged_children_ids.includes(to_id) || // new parent can't be child of dragged element
        dragged_id === to_id || // dragged element can't be its own parent
        typeof to_id === "undefined" || // have to drag to valid element
        to_parent_ids.includes(dragged_id) // dragged element can't be parent of new parent
      ) {
        invalid = true;
      }

      return invalid;
    },
    handleMove(e) {
      const draggedOn = e.to;
      const draggedOnParent = e.to.parentNode;

      // if (draggedOn.classList.contains('has-children')) {
      draggedOn.classList.remove("hide-children");
      draggedOnParent.classList.remove("parent-hide-children");
      draggedOn.classList.add("show-children");
      draggedOnParent.classList.add("parent-show-children");
      // }

      if (this.isMoveInvalid(e)) {
        return false;
      }
    },
    handleChoose(e) {
      return e;
      // console.log(e)
    },

    async removeParent(team, parent_id) {
      const parents = team.parents.filter((parent) => {
        return parent.id !== parent_id;
      });
      if (this.saving) {
        const put = await this.axios.put("/swarms/" + team.id, {
          parents: parents,
        });
        return put;
      }
    },

    async addParent(team, parent_id) {
      const parentIds = team.parents.map((parent) => {
        return parent.id;
      });
      if (this.saving) {
        if (!parentIds.includes(parent_id)) {
          parentIds.push(parent_id);
          const put = await this.axios.put("/swarms/" + team.id, {
            parents: parentIds,
          });
          return put;
        }
      }
    },
    getParentIds(team_el) {
      const parent = team_el.parentNode;
      let parent_ids = [];
      if (
        parent &&
        typeof parent.dataset !== "undefined" &&
        typeof parent !== "undefined"
      ) {
        if (
          !parent_ids.includes(parent.dataset.id) &&
          typeof parent.dataset.id !== "undefined"
        ) {
          parent_ids.push(parent.dataset.id);
        }
        parent_ids = parent_ids.concat(this.getParentIds(parent));
      }
      return parent_ids;
    },
    getChildIds(team_el) {
      const children = Array.from(team_el.querySelector("ul").children);
      let children_ids = children.map((child_el) => {
        return child_el.dataset.id;
      });

      children.forEach((child) => {
        children_ids = children_ids.concat(this.getChildIds(child));
      });
      return children_ids;
    },
    handlestart() {
      const body = document.querySelector("body");
      body.classList.add("moving");
      this.dragging = true;
    },
    async handleend(e) {
      const body = document.querySelector("body");
      const target_team_id = parseInt(e.to.dataset.id);
      const item_team_id = parseInt(e.item.dataset.id);
      const source_team_id = parseInt(e.from.dataset.id);
      const to = e.to;
      const to_parent = to.parentNode;

      body.classList.remove("moving");
      body.classList.remove("cloning");

      this.cloning = false;
      this.$emit("endmove", e);
      this.dragging = false;

      to.classList.add("show-children");
      to_parent.classList.add("parent-show-children");

      try {
        const getTeam = await this.axios.get("/swarms/" + item_team_id);
        /*
        .then(()=>{
          console.log("finished")
        });
        **/
        // console.log(getTeam);
        let team = getTeam.data;

        const parentIds = team.parents.map((parent) => {
          return parent.id;
        });

        if (
          item_team_id !== target_team_id &&
          !parentIds.includes(target_team_id) &&
          team
        ) {
          //* Update parents in single axios call to prevent issues when refreshing
          let parents = [...team.parents];
          if (!this.cloning && this.saving) {
            parents = parents.filter((parent) => {
              return parent.id !== source_team_id;
            })
          }
          parents = parents.map(parent => parent.id);
          parents.push(target_team_id);
          await this.axios.put("/swarms/" + team.id, {
            parents: parents,
          });
          // if (!this.cloning) {
          //   if (this.saving) {
          //     const updated = await this.removeParent(team, source_team_id);
          //     team = updated.data;
          //   }
          // }
          // await this.addParent(team, target_team_id);
        } else {
          return false;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    setActiveTeam(team) {
      this.activeTeam = team;
      this.$emit("setActiveTeam", team);
    },
    setActiveTeamManagers(team) {
      this.$emit("setActiveTeamManagers", team);
    },
    setTree(team) {
      // console.log('component settree',team)
      this.$emit("setTree", team);
    },
  },
  name: "nested-draggable",
};
</script>

<style lang="scss">
.hide-children {
  li:after,
  li:before {
    display: none !important;
  }

  .team-item {
    display: none;
  }

  .dragArea {
    display: none;
  }
}

.moving {
  .page-tree {
    padding-bottom: 100px;
  }

  ul.tree-ul .team-row > .team-content:hover,
  ul.tree-ul .team-row > .team-content.active {
    background-color: #fff;
  }

  .dragArea {
    outline: 2px dashed #d9dce8;
    min-height: 24px;

    &.show-children {
      padding-bottom: 24px;
    }
  }
}

.sortable-ghost {
  background-color: #fff;

  li {
    display: none !important;
  }

  .dragArea,
  + .dragArea {
    display: none !important;
    padding-bottom: 0 !important;
  }
}

.item-segment {
  &:after {
    display: none !important;
  }
}
</style>