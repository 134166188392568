<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="border-bottom-0 modal-header bgc-okergeel">
      <span class="text-sentence">{{ $t("message.import_teams") }}</span>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-end">
        <label class="d-block mb-0 text-sentence" for="userfile">
          <input
            id="userfile"
            name="userfile"
            @change="readFile($event)"
            type="file"
            class="d-none btn btn-secondary styled-input"
          />
          <span class="d-block btn btn-secondary me-2"
            ><i class="fas fa-upload"></i>&nbsp;{{ computedFilename }}</span
          >
        </label>
        <a
          href="/VitaminsTeamTemplate.csv"
          download
          class="btn btn-secondary me-2"
        >
          <i class="fas fa-download"></i>
          <span class="text-sentence">{{ $t("message.download_template") }}</span>
        </a>
        <button class="btn btn-secondary" @click="createOptionsCSV">
          <i class="fas fa-download"></i>
          <span class="text-sentence">{{ $t("message.download_import_keys") }}</span>
        </button>
      </div>
      <div class="row">
        <div class="results col-12">
          <div class="row">
            <div
              class="col-12 position-relative invalid mb-5"
              v-if="invalidRows.length"
            >
              <h3 class="mb-3 text-sentence">
                {{ $t("message.x_invalid_rows", { amt: invalidRows.length }) }}
              </h3>
              <ul
                class="
                  list-style-none
                  small
                  text-danger
                  m-0
                  p-0
                  import_error_field_list
                "
              >
                <li class="p-0 mb-1" v-for="(error, i) in errorFields" :key="i">
                  {{ error }}
                </li>
              </ul>
              <table class="p-0 mt-0 table table-striped">
                <thead class="bgc-koraalrood c-50">
                  <tr>
                    <th v-for="(col, i) in columns" :key="i" class="text-sentence d-table-cell">
                      {{ $t("message." + col) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(record, i) in invalidRows" :key="i">
                    <td v-for="(col, key) in filteredRecord(record)" :key="key">
                      <template
                        v-if="key != 'parents' && key != 'invalidFields'"
                      >
                        <span
                          :class="
                            record.invalidFields.includes(key) && 'text-danger'
                          "
                          >{{ col }}</span
                        >
                      </template>
                      <template v-else-if="key == 'root'">
                        <span
                          :class="
                            record.invalidFields.includes(key)
                              ? 'text-danger'
                              : ''
                          "
                        >
                          {{
                            [true, false].includes(col)
                              ? col
                                ? "Yes"
                                : "No"
                              : col
                          }}</span
                        >
                      </template>
                      <template v-else-if="key == 'parents'">
                        <span
                          :class="
                            record.invalidFields.includes(key)
                              ? 'text-danger'
                              : ''
                          "
                        >
                          {{
                            col && Array.isArray(col) ? col.join(", ") : col
                          }}</span
                        >
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 ready" v-if="results">
              <div v-if="showPostProcess">
                <p class="small text-sentence">
                  {{ $t("message.x_records_processed", { amt: processed }) }}
                </p>
                <p class="small text-sentence">
                  {{
                    $t("message.x_teams_imported_successfully", {
                      amt: successRecords,
                    })
                  }}
                </p>
                <p class="small text-sentence">
                  {{
                    $t("message.x_teams_could_not_be_imported", {
                      amt: errorRecords,
                    })
                  }}
                </p>
                <div class="errors-import" v-if="errors.length">
                  <h4 class="text-sentence">{{ $t("message.the_following_errors_occurred") }}</h4>
                  <div
                    class="error"
                    v-for="(error, i) in orderedErrors"
                    :key="i"
                  >
                    <span class="text-sentence">{{ $t("message.row") }} {{ error.row }} &ndash;</span>
                    <span
                      v-for="(msg, i) in error.error.response.data.data[0]
                        .messages"
                      :key="i"
                    >
                      <span class="field" v-if="msg.field"
                        >{{ error.record[msg.field] }}:</span
                      >
                      {{ msg.message }}
                    </span>
                  </div>
                </div>
                <div class="actions mt-5 text-center">
                  <button
                    class="button btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    <span class="text-sentence">{{ $t("message.close") }}</span>
                  </button>
                </div>
              </div>
              <div v-if="firstResults.length">
                <h3 class="mb-3 text-sentence">
                  {{
                    $t("message.first_x_records", { amt: firstResults.length })
                  }}
                </h3>
                <div style="overflow-x: auto">
                  <table class="p-0 mt-0 table table-striped">
                    <thead
                      class="
                        bgc-okergeel
                        c-50
                        w-100
                        rounded-top
                        overflow-hidden
                      "
                    >
                      <tr>
                        <th v-for="(col, i) in columns" :key="i" class="text-sentence d-table-cell">
                          {{ $t("message." + col) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(record, i) in firstResults" :key="i">
                        <td
                          v-for="(col, key) in filteredRecord(record)"
                          :key="key"
                        >
                          <template v-if="key != 'parents' && key != 'root'">{{
                            col
                          }}</template>
                          <template v-else-if="key == 'root'">{{
                            col ? "Yes" : "No"
                          }}</template>
                          <template v-else-if="key == 'parents'">{{
                            col.join(", ")
                          }}</template>
                          <template v-else>{{ key }}</template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    loadingscreen
                    position-absolute
                  "
                  v-if="processing"
                >
                  <div
                    class="
                      loadingbar
                      d-flex
                      align-items-center
                      justify-content-center
                      position-relative
                    "
                  >
                    <div class="complete-pct text-white position-absolute">
                      {{ pctComplete }}%
                    </div>
                    <div
                      :class="
                        parseInt(pctComplete) >= parseInt(pct * 10)
                          ? 'done'
                          : 'not-done'
                      "
                      class="loading-block"
                      v-for="pct in 10"
                      :key="pct"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="actions mt-5">
                <button
                  v-if="showImportButton"
                  @click.prevent="handleImport($event)"
                  type="button"
                  class="w-100 btn btn-primary"
                >
                  <div
                    style="height: 12px; width: 12px"
                    v-if="processing"
                    class="spinner-border"
                    role="status"
                  >
                    <span class="visually-hidden">{{ $t("message.loading") }}...</span>
                  </div>
                  <span v-else class="text-sentence">{{ $t("message.import_teams") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      filename: null,
      currentUser: this.$store.getters.getUser,
      columns: ["title", "root", "parents", "description"],
      processing: false,
      processed: 0,
      successRecords: 0,
      errorRecords: 0,
      showErrors: false,
      showPostProcess: false,
      invalidRows: [],
      errors: [],
      results: [],
      reader: new FileReader(),
      moment: moment,
      file: null,
    };
  },
  computed: {
    computedFilename() {
      return this.filename ?? this.$t("message.click_to_add_csv");
    },
    showImportButton() {
      return this.results.length > 0;
    },
    firstResults() {
      return this.results.slice(0, 10);
    },
    pctComplete() {
      return ((this.processed / this.results.length) * 100).toFixed(0);
    },
  },
  created() {
    this.reader.onload = (e) => {
      let result = this.reader.result;
      if (result.match(/�/)) {
        this.reader.readAsText(this.file, "windows-1252");
      } else {
        this.parseFile(e);
      }
    };
  },
  methods: {
    filteredRecord(record) {
      let filtered = { ...record };
      delete filtered.rowIndex;
      delete filtered.invalidFields;
      return filtered;
    },
    async handleImport(e) {
      if (!this.processing) {
        let el = e.target;
        el.classList.add("loading");
        await this.importValidTeams();
        this.showPostProcess = true;
        this.results = [];
        this.invalidRows = [];
        // console.log('emitting event');
        this.$emit("importedTeams");
      }
    },
    async importValidTeams() {
      this.processing = true;
      this.processed = 0;
      this.successRecords = 0;
      this.errorRecords = 0;

      for (const result of this.results) {
        result["isbase"] = result["root"];
        result["body"] = result["description"];
      }

      const { data } = await this.axios.post("/swarms/import", this.results);
      this.processed = data.processed;
      this.successRecords = data.successCount;
      this.errorRecords = data.errorCount;
      this.errors = data.errors;
      // let promises = this.results.map((result) => {
      //   const isbase = result['root'];
      //   const body = result['description'];
      //   return this.axios.post('/swarms/import', {
      //     ...result,
      //     isbase,
      //     issegment: false,
      //     body
      //   })
      //     .then(() => {
      //       this.processed++;
      //       this.successRecords++;
      //     })
      //     .catch(r => {
      //       this.processed++;
      //       this.errorRecords++;
      //       this.errors.push({
      //         row: result.rowIndex,
      //         error: r,
      //         record: result,
      //       })
      //     })
      // });
      //
      // return Promise.all(promises);
    },
    closePostProcess() {
      this.results = [];
      this.errors = [];
      this.successRecords = 0;
      this.errorRecords = 0;
      this.showPostProcess = false;
      this.showErrors = false;
      this.invalidRows = [];
      this.processing = false;
      this.processed = 0;
      this.filename = null;
      this.file = null;
    },
    validateTeam(team) {
      let invalidFields = [];
      let valid = true;

      let required = ["title"];

      required.forEach((field) => {
        if (!team[field]) {
          valid = false;
          invalidFields.push(field);
          team[field] = this.$t("message.$ERR_REQ");
        }
      });

      if (team["root"] && team["parents"].length > 0) {
        // console.log(team['title'], team['parents']);
        valid = false;
        invalidFields.push("root");
        invalidFields.push("parents");
        team["root"] = this.$t("message.no_parents_in_base");
        team["parents"] = this.$t("message.no_parents_in_base");
      }

      team.invalidFields = invalidFields;

      return {
        valid,
        team,
      };
    },
    generateListOptions() {
      if (this.listoptions) {
        return this.listoptions;
      }
    },
    async createOptionsCSV() {
      // const {data: { listoptions: listOptions }} = await this.axios.get('/settings/fieldoptions');
      // console.log(listOptions);
      // Add root keys to list options
      const listOptions = [];
      listOptions.push({
        key: "root",
        options: [
          {
            key: "0",
          },
          {
            key: "1",
          },
        ],
      });
      // Convert to CSV
      let headers = [listOptions.map((heading) => heading.key).join(";")];
      const length = Math.max(
        ...listOptions.map((heading) => heading.options.length)
      );

      const rows = [];
      for (let i = 0; i < length; i++) {
        rows.push(
          listOptions
            .map((heading) =>
              heading.options[i] ? heading.options[i].key : ""
            )
            .join(";")
        );
      }
      let finalArray = headers.concat(rows);
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += finalArray.join("\r\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "vitamins_team_import_keys.csv");
      document.body.appendChild(link);
      link.click();
    },
    parseFile(e) {
      this.closePostProcess();
      const rows = e.target.result.split("\n");
      let teams = [];
      this.results = [];

      rows.forEach((row, index) => {
        let empty = true;
        let splitRow = row.split(";");

        this.columns.forEach((column, colIndex) => {
          if (
            splitRow[colIndex] &&
            !splitRow[colIndex].match(/^\s+$/) &&
            splitRow[colIndex] != "\r"
          ) {
            splitRow[colIndex] = splitRow[colIndex].split("\r").join("").trim();
            empty = false;
          }
        });

        if (!empty && index > 0) {
          let team = {};
          this.columns.forEach((column, colIndex) => {
            team[column] = splitRow[colIndex];
          });

          team["root"] = parseInt(team["root"]) > 0;
          team["parents"] = team["parents"]
            .replace("\r", "")
            .split(",")
            .filter((entity) => entity != "");

          let validatedTeam = this.validateTeam(team);

          if (validatedTeam.valid) {
            team.rowIndex = index + 1;
            teams.push(team);
          } else {
            this.invalidRows.push(validatedTeam.team);
          }
        }
      });
      this.results = teams;
    },
    readFile(e, encoding = "utf-8") {
      let files = null;
      files = e.target.files;
      this.filename = files[0].name;
      this.file = files[0];
      this.reader.readAsText(files[0], encoding);
      e.target.value = "";
    },
  },
};
</script>