<template>
  <div class="modal-content">
    <div class="px-2 pt-2">
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="pt-0 modal-header">
      <div class="modal-title w-100 flex-grow-1">
        <label class="d-block text-sentence" for="">{{ $t("message.presets") }}</label>
        <select class="w-100" name="" id="">
          <option value=""></option>
          <option value=""></option>
          <option value=""></option>
        </select>
      </div>
    </div>
    <div class="modal-body">
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t("message.add_a_filter") }}</label>
        <div class="d-flex align-items-center">
          <select class="w-100" name="" id="">
            <option value="">{{ $t("message.country") }}</option>
            <option value=""></option>
            <option value=""></option>
          </select>
          <button type="button" class="btn-close px-3 flex-shrink-0"></button>
        </div>
      </div>
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t("message.add_a_filter") }}</label>
        <div class="d-flex align-items-center">
          <select class="w-100" name="" id="">
            <option value="">{{ $t("message.jobLevel") }}</option>
            <option value=""></option>
            <option value=""></option>
          </select>
          <button type="button" class="btn-close px-3 flex-shrink-0"></button>
        </div>
      </div>
      <div class="filter-row mb-3">
        <label for="" class="text-sentence">{{ $t("message.add_a_filter") }}</label>
        <div class="d-flex align-items-center">
          <select class="w-100" name="" id="">
            <option value="">{{ $t("message.gender") }}</option>
            <option value=""></option>
            <option value=""></option>
          </select>
          <button type="button" class="btn-close px-3 flex-shrink-0"></button>
        </div>
      </div>

      <div
        class="
          add-filter
          bgc-theme
          c-200
          py-2
          px-3
          mb-3
          border-top border-bottom
        "
      >
        <button class="btn btn-secondary btn-sm">
          <img src="../../../assets/icons/icon-navigation-add.svg" alt="" />
          <span class="text-sentence">{{ $t("message.filter") }}</span>
        </button>
      </div>
      <label for="" class="text-sentence">{{ $t("message.saveAsAPreset") }}</label>
      <input
        type="text"
        class="input-lg"
        :placeholder="$t('message.add_title')"
      />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        <span class="text-sentence">{{ $t("message.cancel") }}</span>
      </button>
      <button type="button" class="btn btn-primary">
        <span class="text-sentence">{{ $t("message.save") }}</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user"],
};
</script>